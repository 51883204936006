const parseUtmSessionStorage = () => {
  const utmLabels: Record<string, string> = {};

  Object.keys(sessionStorage).forEach((key: string) => {
    if (key.includes('utm_')) {
      utmLabels[key] = sessionStorage[key];
    }
  });

  return utmLabels
}

export default parseUtmSessionStorage
